<template>
    <div>
        <h1 class="title">Settings</h1>

        <p class="mb-4">If the server uses a different private key for the access token, you'll need to login again after switching environments.</p>

        <label class="label">Environment</label>
        <div class="dropdown" :class="{ 'is-active': environmentDropdownActive }">
            <div class="dropdown-trigger">
                <button class="button environment-menu" aria-haspopup="true" aria-controls="dropdown-menu" @click="environmentDropdownActive = !environmentDropdownActive" @blur="delayCloseEnvironmentDropdown">
                    <span class="is-flex-grow-1 has-text-left">{{ environmentDictionary[currentEnvironment] }}</span>
                    <span class="icon is-small">
                        <font-awesome-icon :icon="['fa', 'angle-down']" />
                    </span>
                </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
                <div class="dropdown-content">
                    <a v-for="(index, environmentKey) in environmentDictionary" :key="'environment' + index"
                        :class="currentEnvironment == environmentKey ? 'dropdown-item is-active' : 'dropdown-item'" @click="setEnvironment(environmentKey)">
                        {{ environmentDictionary[environmentKey] }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Settings",
        computed: {
            currentEnvironment: function() {
                return this.$store.state.environment;
            },
            environmentDictionary: function() {
                return this.$store.state.environmentDictionary;
            }
        },
         data() {
            return {
                environmentDropdownActive: false
            }
        },
        methods:{
            delayCloseEnvironmentDropdown() {
                setTimeout(() => this.environmentDropdownActive = false, 250);
            },
            setEnvironment(environment) {
                this.environmentDropdownActive = false;
                this.$store.commit('updateEnvironment', environment);
            }
        }
    };
</script>

<style scoped>
    .environment-menu {
        width: 8em;
    }
</style>